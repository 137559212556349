//---------------------------------------------------------------------
// Tier
//---------------------------------------------------------------------

.tier {
    @include tier();
}

.tier__inner {
    @include wrapper();
}

.tier__inner--constrain {
    @include bpLarge() {
        max-width: remify(800);
    }
}

.tier--banner {
    padding: remify(30 0);
}

.tier--thin {
    padding: remify(15 0);
}

.tier--gray {
    background-color: palette(neutral, athens-gray);
}

.tier--blue {
    background-color: palette(brand, blue-mid);
}

.tier--maroon {
    background-color: palette(brand, maroon);
}

.tier--eco {
    background-color: palette(brand, green-mid);
}

.Tier--v2 {
    padding-top: remify(36);
    padding-bottom: remify(36);

    @include bpMaxLarge() {
        padding-top: remify(16);
        padding-bottom: remify(16);
    }
}

.tier__inner--v2 {
    @include wrapperV2();
}
