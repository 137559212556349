//----------------------------------------------------------------------------
// Utilities: Background Color
//----------------------------------------------------------------------------

.bg {
    // --light, --medium, --dark, --accent, --eco will be used along with their default background image & a dynamic image sent from sitecore might override the default image
    // The default background image & it's position are managed by the Classes below under the "DefaultBg" class name Block.
    // Based on the theme name sent from sitecore the background image will be added or removed, but these classes help with just bg color
    // So, if Theme Name is "Light" or "Solid-Light" the background color will come from the "bg--light" class, When Theme Name is "Light", then the bg image will be added by the "DefaultBg__image--light" class
    &--light {
        background-color: palette(neutral, white) !important;
        background-size: remify(250);

        @include bpMaxLarge() {
            background-size: remify(150);
        }
    }

    &--medium {
        background-color: palette(neutral, athens-gray) !important;
        background-size: remify(250);

        @include bpMaxLarge() {
            background-size: remify(150);
        }
    }

    &--dark {
        background-color: palette(brand, maroon) !important;
        background-size: remify(250);

        @include bpMaxLarge() {
            background-size: remify(150);
        }
    }

    &--accent {
        background-color: palette(brand, blue-mid) !important;
        background-size: remify(250);

        @include bpMaxLarge() {
            background-size: remify(150);
        }
    }

    &--eco {
        background-color: palette(brand, green-mid) !important;
        background-size: remify(250);

        @include bpMaxLarge() {
            background-size: remify(150);
        }
    }

    &--mhf-maroon {
        background-color: palette(brand, mhf-maroon) !important;
    }

    &--image-light {
        background-color: palette(neutral, white) !important;
        background-image: url('../../media/images/background-theme.png');
        background-repeat: repeat;
        background-size: contain;
    }

    &--image-medium {
        background-color: palette(neutral, athens-gray) !important;
        background-image: url('../../media/images/background-theme.png');
        background-repeat: repeat;
        background-size: contain;
    }

    &--image-dark {
        background-color: palette(brand, maroon) !important;
        background-image: url('../../media/images/background-theme.png');
        background-repeat: repeat;
        background-size: contain;
    }

    &--image-accent {
        background-color: palette(brand, blue-mid) !important;
        background-image: url('../../media/images/background-theme.png');
        background-repeat: repeat;
        background-size: contain;
    }

    &--image-eco {
        background-color: palette(brand, green-mid) !important;
        background-image: url('../../media/images/background-theme.png');
        background-repeat: repeat;
        background-size: contain;
    }

    &--color-image-medium {
        background-color: palette(neutral, athens-gray) !important;
        background-image: url('../../media/images/background-theme-peds.png');
        background-repeat: repeat;
        background-position: right bottom;
    }
}

// Class names for default background image
.DefaultBg {
    &__image {
        &--light {
            background-image: url('../../media/images/lightDefault.png');
            background-repeat: no-repeat;
            background-position: right bottom;
            background-size: remify(250);

            @include bpMaxLarge() {
                background-size: remify(150);
            }
        }

        &--medium {
            background-image: url('../../media/images/mediumDefault.png');
            background-repeat: no-repeat;
            background-position: right bottom;
            background-size: remify(250);

            @include bpMaxLarge() {
                background-size: remify(150);
            }
        }

        &--dark {
            background-image: url('../../media/images/maroonDefault.png');
            background-repeat: no-repeat;
            background-position: right bottom;
            background-size: remify(250);

            @include bpMaxLarge() {
                background-size: remify(150);
            }
        }

        &--accent {
            background-image: url('../../media/images/blueDefault.png');
            background-repeat: no-repeat;
            background-position: right bottom;
            background-size: remify(250);

            @include bpMaxLarge() {
                background-size: remify(150);
            }
        }

        &--eco {
            background-image: url('../../media/images/ecoDefault.png');
            background-repeat: no-repeat;
            background-position: right bottom;
            background-size: remify(250);

            @include bpMaxLarge() {
                background-size: remify(150);
            }
        }
    }
}
